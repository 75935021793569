body * {
  font-family: Arial, Helvetica, sans-serif;
}

@media (orientation: landscape) {
  body {
    background-color: #131314;
    opacity: 1;
    /* if you need only portrait orientation */
    /*
    background: #131314 url(./../public/images/rotate.png) 0 0 no-repeat;
    background-size: 35% auto;
    background-position: center top;
    opacity: 0;*/
  }
}

@media (orientation: portrait) {
  body {
    /*opacity: 1;*/
    /* if you need only portrait landscape */
    background: #131314 url(./../public/images/rotate.png) 0 0 no-repeat;
    background-size: 80% auto;
    background-position: center top;
    opacity: 0;
  }
}

body button {
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
  cursor: pointer;
}
body button:hover {
  background-color: #cccccc;
}
